<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.home') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-0">
          <p class="pl-5">
            {{ $t('description.agencyTop.topExplanation') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <!-- 契約検索 -->
                  <v-btn
                    class="font-weight-black"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    dark
                    slot="activator"
                    @click="onClickSearchContractButton()"
                    >{{ $t('label.searchContract') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.agencyTop.searchExplanation') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pt-y mb-0" style="max-height:75px">
        <v-col class="mb-0 pb-0">
          <p class="font-weight-black px-4 pt-4 mb-0" style="font-size:24px">
            {{ $t('label.contractList') }}
          </p>
        </v-col>
        <v-col class="mb-0 pb-0">
          <v-layout class="float-right">
            <v-switch
              color="next"
              :input-value="false"
              :label="$t('button.showWithdrawalContract')"
              :hide-details="true"
              @change="onChangeWithdrawContractButton"
            ></v-switch>
            <v-chip
              ripple
              class="font-weight-black ml-4"
              style="margin-top:15px;"
              :disabled="!isClearSearchConditionBtnEnable"
              @click="onClickClearButton()"
              >{{ $t('button.conditionClear')
              }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-layout>
        </v-col>
      </v-row>
      <v-row style="width:100%" class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-data-table
            calculate-widths
            class="elevation-1"
            :headers="headers"
            :items="records"
            :options.sync="options"
            :server-items-length="total"
            :loading="isLoading"
            :loading-text="$t('description.contractReading')"
            :no-data-text="$t('error.contractListNotFound')"
            :footer-props="{
              itemsPerPageText: '1ページあたりの行数',
              itemsPerPageOptions: itemsPerPageOptions,
            }"
          >
            <!-- 詳細ボタン -->
            <template v-slot:item.detail="{ item }" class="pa-0">
              <router-link
                :to="{
                  name: 'ContractDetail',
                  params: { contractId: item.id },
                }"
                target="_blank"
                class="router-link-transparent"
                style="text-decoration:none"
              >
                <template>
                  <v-chip
                    class="font-weight-black float-center"
                    color="next"
                    dark
                    ripple
                    link
                    >{{ $t('header.contractListTable.detail') }}</v-chip
                  >
                </template>
              </router-link>
            </template>
            <template v-slot:item.status="{ item }"
              ><strong
                v-if="item.lockoutStatus === lockedStatus"
                :class="checkLockoutStyle(item.lockoutStatus)"
              >
                {{ item.status }}({{ $t('label.lockout') }})
              </strong>
              <template v-else>
                {{ item.status }}
              </template>
            </template>
            <template v-slot:item.startStatus="{ item }">
              <strong
                v-if="item.isStrong"
                :class="item.isAlert ? alertStyle : ''"
              >
                {{ item.startStatus }}
              </strong>
              <template v-else>
                {{ item.startStatus }}
              </template>
            </template>
            <template v-slot:item.note="{ item }" class="pa-0">
              <p
                v-for="(message, index) in splitMessageLine(item.note)"
                :key="index"
                class="ma-0 pa-0"
                style="font-size:14px"
                align="left"
              >
                {{ message }}
              </p>
            </template>
            <template v-slot:item.fullNameKana="{ item }" class="pa-0">
              <p
                  v-for="(message, index) in splitMessageLine(item.fullNameKana)"
                  :key="index"
                  class="ma-0 pa-0"
                  style="font-size:14px"
                  align="left"
              >
                {{ message }}
              </p>
            </template>

          </v-data-table>
          <v-row>
            <v-col>
              <v-btn
                class="font-weight-black float-right"
                color="next"
                dark
                @click="onClickCsvDownloadButton()"
                >{{ $t('button.downloadCsv') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <ContractListSearchDialog
        @onSuccess="onSuccessContractListSearch"
        @onClear="onClearSearchCondition"
        ref="contractListSearchDialog"
      ></ContractListSearchDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <!-- 備考編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditNoteDialog"
        :title="$t('header.contractListTable.note')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditNote"
        :onClickPositiveButton="updateNote"
        :targets="editNoteTargets"
      />
    </v-layout>
  </v-container>
</template>
<script>
import moment from 'moment';
import {
  ContractListTableHeader,
  ContractListDisplayDefault,
  ContractStatusDisplay,
  ContractStatus,
  ContractSearchResultCsv,
  MaxLength,
  ContractorLockoutStatus,
  TextStyle,
} from '@/lib/const';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import { Role } from '@/lib/role';
import { downloadCsv, getCsvFileName } from '@/lib/csv';
import ContractListSearchDialog from '@/components/organisms/agency/ContractListSearchDialog';
import { getContracts, updateContractDetail } from '@/apis/agency/contracts';
import { getInsuranceTypeList } from '@/apis/agency/insuranceTypes';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import { mapGetters } from 'vuex';
import { splitMessageLine, getValidStartDate } from '@/lib/commonUtil';

export default {
  name: 'Home',
  components: {
    ErrorDialog,
    FormsDialog,
    ContractListSearchDialog,
    CompletedDialog,
  },

  data: vm => ({
    headers: ContractListTableHeader,
    records: [],

    // 案件一覧取得オプション
    options: {
      page: ContractListDisplayDefault.page,
      itemsPerPage: ContractListDisplayDefault.itemsPerPage,
      sortBy: [],
      sortDesc: [],
    },

    // 1ページあたりの表示件数の選択肢
    itemsPerPageOptions: ContractListDisplayDefault.itemsPerPageOptions,

    // 案件一覧取得件数
    total: 0,

    // 案件一覧取得条件
    contractListCondition: {},

    // 案件一覧検索条件
    contractListSearchCondition: {},

    // ソートキー名のマッピング
    sortKey: {
      startDate: 'startDate',
      status: 'status',
      latestUploadDate: 'updatedAt',
    },

    // 備考データ (デフォルト)
    editNoteDefaultTargets: [
      {
        value: null,
        targetName: 'note',
        type: 'textarea',
        label: vm.$t('header.contractListTable.note'),
        text: null,
        rule: `max:${MaxLength.ContractNote}`,
        key: 'note',
        max: MaxLength.ContractNote,
      },
    ],

    // 備考編集データ
    editNoteTargets: [],

    // 編集中の案件ID
    editContractId: null,

    // 案件一覧
    contracts: [],

    // 案件の選別が必要であるかどうか
    needsSelectContract: true,

    // 備考編集ダイアログが表示されているかどうか
    showEditNoteDialog: false,

    // ローディング中であるかどうか
    isLoading: true,

    // アラート文字のスタイル
    alertStyle: TextStyle.Alert,

    // 案件ごとの備考の最大文字数
    contractNoteMaxLength: MaxLength.ContractNote,

    // ロックアウトステータス
    lockedStatus: ContractorLockoutStatus.Locked,

    // 検索条件クリアボタンの活性フラグ
    isClearSearchConditionBtnEnable: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchContracts();
      },
      deep: true,
    },
    contractListCondition: {
      handler() {
        this.isClearSearchConditionBtnEnable =
          Object.keys(this.contractListSearchCondition).length > 0;
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    ...mapGetters('user', ['userDetail']),

    // オプションの取得
    getOption() {
      return {
        perPage: this.options.itemsPerPage,
        pageNumber: this.options.page,
        ...(this.options.sortBy.length > 0 && {
          sortKey: this.sortKey[this.options.sortBy[0]],
        }),
        ...(this.options.sortDesc.length > 0 && {
          isDesc: this.options.sortDesc[0],
        }),
      };
    },

    // 編集中の案件情報を取得
    getEditContract() {
      // 編集中の案件IDと一致する場合
      return this.contracts.find(
        contract => contract.id === this.editContractId
      );
    },

    // 案件一覧の取得
    async fetchContracts() {
      // 案件一覧取得条件
      const contractListCondition = this.getContractListCondtion(
        this.contractListSearchCondition
      );

      // ステータス条件ありかつ、ステータス指定が存在しない場合
      if (
        contractListCondition.status &&
        contractListCondition.status.length < 1
      ) {
        // 0件取得時と同様とみなし、以降の処理を中止する
        return this.updateContracts({
          count: 0,
          rows: [],
        });
      }

      // 案件一覧の取得
      const contracts = await getContracts(
        this.getOption(),
        contractListCondition
      ).catch(() => {
        // 空データをセットする
        this.$set(this, 'records', [{}]);
      });

      this.$set(this, 'isLoading', false);

      // 案件一覧が取得できなかった場合、以降の処理を中止する
      if (!contracts) return;

      // 案件一覧取得条件の保存
      this.$set(this, 'contractListCondition', contractListCondition);
      // 案件一覧の保存
      this.$set(this, 'contracts', contracts.rows);
      // 案件一覧表示の更新
      this.updateContracts(contracts);
    },

    // 案件一覧表示の更新
    updateContracts(contracts) {
      // 表示値のマッピング
      const records = this.mapContracts(contracts);

      // 案件一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', contracts.count);
    },

    // 案件一覧取得条件の取得
    getContractListCondtion(contractListSearchCondition) {
      const condition = {
        ...contractListSearchCondition,
      };

      // 半除外ステータス
      const semiExcludeStatus = String(ContractStatus.Withdrawal);
      // 全ステータス
      const allStatus = Object.keys(ContractStatus).map(key =>
        String(ContractStatus[key])
      );

      // 案件の選別が必要であるかつ、ステータス指定ありかつ、半除外ステータス指定ありの場合
      if (
        this.needsSelectContract &&
        condition.status &&
        condition.status.includes(semiExcludeStatus)
      ) {
        // 半除外ステータス以外を選別する
        condition.status = condition.status.filter(
          status => status !== semiExcludeStatus
        );
      }

      // 案件の選別が必要であるかつ、ステータス指定なしの場合
      if (this.needsSelectContract && !condition.status) {
        // 半除外ステータス以外を条件に指定する
        condition.status = allStatus.filter(
          status => ![semiExcludeStatus].includes(status)
        );
      }

      // 案件の選別が必要でないかつ、ステータス指定なしの場合
      if (!this.needsSelectContract && !condition.status) {
        condition.status = allStatus
      }

      return condition;
    },

    // 案件一覧データのマッピング
    mapContracts(contracts) {
      return contracts.rows.map(contract => this.mapContract(contract));
    },

    // 案件データのマッピング
    mapContract(contract) {
      // 現在時刻取得
      const currentDate = moment().format('YYYY-MM-DD');
      // 有効な保険始期取得
      const startDate = getValidStartDate(contract.startDate, contract.calculationBaseDate);
      return {
        detail: this.$t('button.editAndReference'),
        id: contract.id,
        officeId: contract.agencyId,
        policyNumber: contract.policyNumber,
        previousPolicyNumber: contract.previousPolicyNumber,
        printingSerialNumber: contract.printingSerialNumber,
        fullName: contract.contractUser.fullName,
        fullNameKana: contract.contractUser.fullNameKana,
        startDate: moment(startDate).format('YYYY/MM/DD'),
        status: ContractStatusDisplay[contract.status],
        startStatus: this.checkInsuranceStartDate(
          currentDate,
          moment(startDate).format('YYYY-MM-DD'),
          contract.status
        ).status,
        isAlert: this.checkInsuranceStartDate(
          currentDate,
          moment(startDate).format('YYYY-MM-DD'),
          contract.status
        ).isAlert,
        isStrong: this.checkInsuranceStartDate(
          currentDate,
          moment(startDate).format('YYYY-MM-DD'),
          contract.status
        ).isStrong,
        note: contract.note,
        staffName: contract.contractAgency.staffName,
        agencyName: contract.contractAgency.name,
        salesCode: contract.agency
          ? contract.agency.sale
            ? contract.agency.sale.salesCode
            : null
          : null,
        contractAgencyCode: contract.contractAgency.code,
        agencyAgencyCode: contract.agency ? contract.agency.agencyCode : null,
        latestUploadDate: moment(contract.updatedAt).format('YYYY/MM/DD HH:mm'),
        lockoutStatus: contract.lockoutStatus,
      };
    },

    // 備考編集のキャンセル
    cancelEditNote() {
      this.$set(this, 'showEditNoteDialog', false);
    },

    // 備考の更新
    async updateNote(item) {
      const data = {
        contractId: this.editContractId,
        note: item.note,
      };
      // 案件情報を更新
      const result = await updateContractDetail(data).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.contractUpdateError'),
          this.$t('error.contractUpdateBadRequest')
        );
      });

      // 案件情報の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      // 案件一覧表示を更新する
      await this.fetchContracts();
      this.$set(this, 'showEditNoteDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    async onClickSearchContractButton() {
      // 種目一覧を取得
      const insuranceTypes = await getInsuranceTypeList().catch(() => {});
      this.$refs.contractListSearchDialog.open(insuranceTypes);

      // 案件一覧検索ダイアログを開くのが初回時のみ初期化
      if (!Object.keys(this.contractListSearchCondition).length) {
        // 検索条件を初期化する
        this.$refs.contractListSearchDialog.init();
      }
    },

    // 案件一覧検索の成功時
    async onSuccessContractListSearch(condition) {
      // 案件一覧取得条件
      const contractListCondition = this.getContractListCondtion(condition);

      // ステータス条件ありかつ、ステータス指定が存在しない場合
      if (
        contractListCondition.status &&
        contractListCondition.status.length < 1
      ) {
        // 0件取得時と同様とみなし、以降の処理を中止する
        return this.$refs.contractListSearchDialog.onErrorSearch();
      }

      // 案件一覧の取得
      const contracts = await getContracts(
        this.getOption(),
        contractListCondition
      ).catch(() => {});

      // 一件もが取得できなかった場合、以降の処理を中止する
      if (contracts['count'] == 0) {
        this.$refs.contractListSearchDialog.onErrorSearch();
        return;
      }

      // 案件一覧検索条件の保存
      this.$set(this, 'contractListSearchCondition', condition);
      // 案件一覧取得条件の保存
      this.$set(this, 'contractListCondition', contractListCondition);
      // 案件一覧の保存
      this.$set(this, 'contracts', contracts.rows);

      // ページングの初期化
      this.$set(this.options, 'page', ContractListDisplayDefault.page);

      // 案件一覧表示の更新
      this.updateContracts(contracts);

      this.$refs.contractListSearchDialog.close();
    },
    // 案件一覧取得条件をクリア
    onClearSearchCondition() {
      this.$set(this, 'contractListSearchCondition', {});
    },

    // 取り下げ案件表示ボタンの押下時
    async onChangeWithdrawContractButton(enabled) {
      // 有効状態に応じて案件の選別必要可否をセットする
      this.$set(this, 'needsSelectContract', !enabled);

      // 案件一覧表示を更新する
      await this.fetchContracts();
    },

    // 検索条件クリアボタン押下時
    async onClickClearButton() {
      this.onClearSearchCondition();
      await this.fetchContracts();
    },

    // ユーザーごとの代理店情報編集
    onClickEditAgencyInfoForIndividual() {
      // ログインユーザーの情報編集に遷移
      this.$router.push('/edit_agency_info_for_individual/me');
    },

    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },

    // 保険始期と当日日付の比較
    checkInsuranceStartDate(currentDate, startDate, status) {
      // 保険始期
      let startStatus = {};
      startStatus.status = '';
      startStatus.isAlert = false;
      startStatus.isStrong = false;

      // 下記のステータスの場合のみ、保険始期までの日数を表示する
      // 未送信・お客さま確認待ち・締結済・計上完了(必要書類送付要）・計上エラー・計上処理実施待ち
      const avaliableContractStatuses = [
        // 有効なステータスの一覧
        ContractStatus.Processing,
        ContractStatus.Waiting,
        ContractStatus.Concluded,
        ContractStatus.AccountedWithDocRequired,
        ContractStatus.AccountingError,
        ContractStatus.UnAccounted,
      ];
      if (avaliableContractStatuses.includes(status)) {
        if (
          moment(startDate).isSameOrBefore(
            moment(currentDate)
              .add(7, 'days')
              .format('YYYY-MM-DD')
          )
        ) {
          // 保険始期 <= 当日+7days
          startStatus.status = this.$t(
            'insuranceStartDateStatus.withinOneWeek'
          );

          // 保険始期 <= 当日
          if (moment(startDate).isSameOrBefore(currentDate)) {
            startStatus.status = this.$t('insuranceStartDateStatus.passed');
            startStatus.isAlert = true;
            startStatus.isStrong = true;
          }
        }
        if (
          moment(startDate).isSame(
            moment(currentDate)
              .add(1, 'days')
              .format('YYYY-MM-DD')
          )
        ) {
          // 保険始期 < 当日+1days
          startStatus.status = this.$t('insuranceStartDateStatus.yesterday');
          startStatus.isAlert = true;
          startStatus.isStrong = true;
        }
        return startStatus;
      } else {
        return startStatus;
      }
    },

    // CSVダウンロードボタンの押下時
    async onClickCsvDownloadButton() {
      const contracts = await getContracts(
        null,
        this.contractListCondition
      ).catch(err => {
        console.log(err);
      });

      // 出力データ数が最大行数を上回った場合
      if (contracts.count > ContractSearchResultCsv.maxRowCount) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.csvExportError'),
          this.$t('error.invalidCsvMaxRowCount', {
            maxRowCount: ContractSearchResultCsv.maxRowCount,
          })
        );

        return;
      }

      // CSV のヘッダー
      const headers = {};
      ContractSearchResultCsv.headers.forEach((header, index) => {
        // ヘッダー名をセットする
        headers[ContractSearchResultCsv.columns[index]] = header;
      });
      
      // CSV の各行
      const rows = contracts.rows.map(contract => {
        const row = {};
        const contractData = this.mapContract(contract);
        ContractSearchResultCsv.columns.forEach(column => {
          console.log(contractData.chargePersonName);
          const data = contractData[column];
          // 案件データをセットする
          row[column] = data;
        });

        return row;
      });

      // CSV をダウンロードする
      downloadCsv(
        rows,
        headers,
        getCsvFileName(this.$t('file.contractSearchResultCsv'))
      );
    },
    checkLockoutStyle(lockoutStatus = null) {
      let statusStyale = '';
      switch (lockoutStatus) {
        case ContractorLockoutStatus.Locked: {
          statusStyale = TextStyle.Alert;
          break;
        }
        case ContractorLockoutStatus.MaximumReSent: {
          statusStyale = TextStyle.Warring;
          break;
        }
        default:
      }

      return statusStyale;
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>
